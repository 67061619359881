export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ApiError: [
      'AuthenticationError',
      'BasicError',
      'PublicationError',
      'UniqueIsrcPublicationError',
      'ValidationError',
    ],
    LiveOrVodConcert: ['LiveConcert', 'VodConcert'],
    Node: [
      'Album',
      'Banner',
      'BannerData',
      'CoreArtist',
      'CoreArtistData',
      'CoreEpoch',
      'CoreEpochData',
      'CoreGenre',
      'CoreGenreData',
      'CoreGroup',
      'CoreGroupData',
      'CoreMovement',
      'CorePartner',
      'CorePartnerData',
      'CoreRole',
      'CoreWork',
      'CoreWorkData',
      'CuratedContent',
      'CuratedContentData',
      'CuratedSlider',
      'CuratedSliderData',
      'Label',
      'LiveConcert',
      'LiveConcertData',
      'PerformanceWork',
      'PerformanceWorkData',
      'Picture',
      'R2Work',
      'Stage',
      'StageData',
      'Stream',
      'Track',
      'TrackSet',
      'User',
      'Video',
      'VideoData',
      'VodConcert',
      'VodConcertData',
    ],
    ParentConcert: ['LiveConcert', 'VodConcert'],
  },
}
export default result
