import { merge } from 'merge-anything'

import type { LogMeta } from 'utils/error-reporting/sentry'

import { isBrowser } from 'utils/environment-guard'
import { log as logToErrorReporting, reportError } from 'utils/error-reporting/sentry'

const shouldLog = () => process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production' && isBrowser()

export enum errorOrigin {
  apiRoute = 'API-ROUTE',
  browser = 'BROWSER',
  ssr = 'SSR',
}

// default is "browser" as it will be the most common
const defaultMeta = { tags: { origin: errorOrigin.browser } }

// ! we have not found a usage for other log-levels like warn or info so far
// ! and have no dedicated logging service for them
// ! this a simple stub to log any kind of information to sentry
// ! if this changes you can integrate with a logging service here

const log = (message: string, meta?: LogMeta): void => {
  logToErrorReporting(message, meta)
}

export const debug = (message: string, ...arguments_: unknown[]): void => {
  if (shouldLog()) {
    console.info(message, ...arguments_)
  }
}

/**
 * Centralized error logging-method
 * can be called with an error or a string, each will log the error to sentry as a side-effect
 * when called with a message, no stacktrace is recorded so errors are preferable in most cases
 *
 * @example
 * import { logger } from 'utils/logger/logger'
 *
 * logger.error(new Error('This will have a stacktrace'))
 * logger.error('this won't')
 */
const error = (errorOrMessage: Error | string, meta?: LogMeta, ...arguments_: unknown[]): void => {
  const combinedMeta = meta ? merge(defaultMeta, meta) : defaultMeta

  if (errorOrMessage instanceof Error) {
    reportError(errorOrMessage, combinedMeta)
  }

  if (typeof errorOrMessage === 'string') {
    logToErrorReporting(errorOrMessage, combinedMeta)
  }

  if (shouldLog()) {
    console.error(errorOrMessage, meta, ...arguments_)
  }
}

export const logger = { debug, error, log }
