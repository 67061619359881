import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'
import CoreDataIcon from 'public/assets/core-data.svg'

export const CORE_DATA_TRANSLATION_KEY = 'core_data'

export const CORE_DATA_ROUTES: RouteConfig = {
  index: {
    icon: CoreDataIcon,
    label: 'Core Data',
    menuLabel: 'Core Data',
    permissions: roleEnum.editor,
    uri: () => '/core-data',
  },
}

export const CORE_DATA_DEFAULT_LIST_SIZE = 30
