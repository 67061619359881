import type { ReactNode } from 'react'

import { Component } from 'react'

import { useException } from 'state/use-exception'

import { ExceptionError } from './exception-error'

type ErrorBoundaryProps = {
  children: ReactNode
  hasException: boolean
  setException: (error: Error | undefined) => void
}

class ErrorBoundaryCatcher extends Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error) {
    this.props.setException(error)
  }

  render() {
    if (this.props.hasException) {
      return <ExceptionError />
    }

    return this.props.children
  }
}

export function ErrorBoundary({ children }: { children: ReactNode }) {
  const { hasException, setException } = useException()

  return (
    <ErrorBoundaryCatcher hasException={hasException} setException={setException}>
      {children}
    </ErrorBoundaryCatcher>
  )
}
