import type { ReactNode } from 'react'

import type { Role } from 'hooks/admin-user-types'
import type { RoutePermission } from 'utils/ts/shared-types'

import { NotificationElement } from 'components/elements/notification'
import { useAdminUser } from 'hooks/use-admin-user'
import { t } from 'utils/i18n/translate'

type RoutePermissionGuardProps = {
  children: ReactNode
  minimumRequiredRole?: RoutePermission
}

export const canUserEnterRoute = (
  minimumRequiredRole: RoutePermission,
  currentAdminUserRoles: Role[] = [],
): boolean => {
  if (minimumRequiredRole === 'all') {
    return true
  }

  if (currentAdminUserRoles.length === 0 || minimumRequiredRole === 'nobody') {
    return false
  }

  return currentAdminUserRoles.includes(minimumRequiredRole) ?? false
}

const needsUser = (minimumRequiredRole: RoutePermission | undefined): boolean => minimumRequiredRole !== 'all'

/**
 * Ensures that users have the correct access permissions to access the current url
 */
export function AuthorizationGuard({ children, minimumRequiredRole }: RoutePermissionGuardProps) {
  const { adminUser } = useAdminUser()

  // * If no user is needed then we can immediately return the children without waiting for a user to be loaded
  if (!needsUser(minimumRequiredRole)) {
    return <>{children}</>
  }

  if (!adminUser) {
    return null
  }

  if (minimumRequiredRole === undefined) {
    return (
      <NotificationElement layout="error">
        {t(
          'No permissions have been defined for this page. This is an unrecoverable error. Please report this to a developer. Please include the url you were trying to access in your report.',
        )}
      </NotificationElement>
    )
  }

  if (!canUserEnterRoute(minimumRequiredRole, adminUser?.roles)) {
    return (
      <NotificationElement layout="error">
        {t(
          'You do not have sufficient permissions to view this page. If you think this is a mistake, please contact your Administrator.',
        )}
      </NotificationElement>
    )
  }

  return <>{children}</>
}
