import type { Breadcrumb, Event, SeverityLevel, User } from '@sentry/nextjs'

import {
  captureEvent,
  captureException,
  captureMessage,
  addBreadcrumb as libraryAddBreadcrumb,
  setUser as librarySetUser,
} from '@sentry/nextjs'

import type { NestedUnknownObject, UnknownObject } from 'utils/ts/utility-types'

export type LogMeta = Partial<{
  contexts: NestedUnknownObject
  extra: UnknownObject
  fingerprint: string[]
  level: SeverityLevel
  tags: {
    [key: string]: boolean | null | number | string | symbol | undefined
  }
  user: User
}>

export function log(message: string, meta?: LogMeta) {
  return captureMessage(message, meta)
}

export function reportError(error: Error, meta?: LogMeta) {
  return captureException(error, meta)
}

export function sendEvent(event: Event) {
  return captureEvent(event)
}

/**
 * Can be used to identify certain actions the user has undertaken, eg. login
 * All consecutive log-events will have a connection to the last breadcrumb in order to make the actions
 * of the user more traceable
 */
export function addBreadcrumb(breadcrumb: Breadcrumb) {
  return libraryAddBreadcrumb(breadcrumb)
}

export function setUser(user: null | User) {
  return librarySetUser(user)
}
