import type { ReactElement } from 'react'

import { cloneElement } from 'react'

import type { ModalResolution } from 'components/elements/modal/modal'

import { makeStateVariable, useStateVariable } from 'utils/state/state'

const setIsVisible = makeStateVariable(false)
const setModal = makeStateVariable<ReactElement | undefined>(undefined)

const modalHandler = (resolve: (value: ModalResolution) => void) => (resolution: ModalResolution) => {
  resolve(resolution)
  setIsVisible(false)
}

export const showModal = (nextModal: ReactElement) =>
  new Promise<ModalResolution>((resolve) => {
    const wrappedModal = cloneElement(nextModal, {
      onCancel: modalHandler(resolve),
      onConfirm: modalHandler(resolve),
    })
    setModal(wrappedModal)
    setIsVisible(true)
  })

const hideModal = () => {
  setIsVisible(false)
}

export const useModal = () => {
  const isVisible = useStateVariable(setIsVisible)
  const modal = useStateVariable(setModal)

  return {
    hideModal,
    isVisible,
    modal,
    showModal,
  }
}
