import type { ComponentPropsWithoutRef, MouseEvent } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { clsx } from 'clsx'

import { t } from 'utils/i18n/translate'

export type ModalResolution = [ModalStates, MouseEvent<HTMLButtonElement>]

type ModalProps = {
  confirmLabel: string
  onCancel?: (resolution: ModalResolution) => Promise<unknown>
  onConfirm?: (resolution: ModalResolution) => Promise<unknown>
} & ComponentPropsWithoutRef<'div'>

export enum ModalStates {
  canceled = 'MODAL_STATE_CANCELED',
  confirmed = 'MODAL_STATE_CONFIRMED',
}

/**
 * Styled Modal Headline
 * @see Modal
 */
export function ModalHeadline({ children }: ComponentPropsWithoutRef<'h3'>) {
  return (
    <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
      {children}
    </h3>
  )
}

/**
 * Styled Modal Paragraph
 * @see Modal
 */
export function ModalParagraph({ children, className }: ComponentPropsWithoutRef<'p'>) {
  return (
    <div className="mt-2">
      <p className={clsx({ [className as string]: !!className, 'text-sm text-gray-500': !className })}>{children}</p>
    </div>
  )
}

/**
 * Component to display a modal dialog that interrupts an action by the user and makes him confirm that the outcome
 * is what she intended. Use the `ModalHeadline`-/`ModalParagraphs`-Components to format the modal body.
 * Currently only useful when employed together with `useModal`-Hook
 *
 * @example
 * const { showModal } = useModal()
 *
 * # prompt is a promise that will be resolved/rejected depending on the selection of the user
 * const prompt = showModal(
 *  <Modal confirmLabel="Delete">
 *    <ModalHeadline>Do you want to delete your account?</ModalHeadline>
 *    <ModalParagraph>This will destroy your account, you will have to sign-up again</ModalParagraph>
 *  </Modal>
 * )
 */
export function Modal({ children, confirmLabel, onCancel, onConfirm, ...props }: ModalProps) {
  return (
    <div
      aria-labelledby="modal-headline"
      aria-modal="true"
      className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
      // biome-ignore lint/a11y/useSemanticElements: use div for now for better browser compatibility
      role="dialog"
      {...props}
    >
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">{children}</div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={(event) => {
            onConfirm && void onConfirm([ModalStates.confirmed, event])
          }}
          type="button"
        >
          {confirmLabel}
        </button>
        <button
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={(event) => {
            onCancel && void onCancel([ModalStates.canceled, event])
          }}
          type="button"
        >
          {t('Cancel')}
        </button>
      </div>
    </div>
  )
}
