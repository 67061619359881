import type { ApolloClientOptions, NormalizedCacheObject } from '@apollo/client'

import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { RetryLink } from '@apollo/client/link/retry'
import { fetch } from 'cross-fetch'
import generatedIntrospection from 'generated/possible-fragments'
import { useMemo } from 'react'

import { isTest } from 'utils/environment-guard'

import { createEntraIdTokenLink } from './links/entra-token-link'
import { generateMissingCacheIds } from './links/generate-cache-ids'
import { graphQLErrorLink } from './links/graphql-error-link'
import { createSentryDebugLink } from './links/sentry-debug-link'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export let apolloClient: ApolloClient<NormalizedCacheObject>

const retries = Number.parseInt(process.env.NEXT_PUBLIC_REQUEST_RETRIES ?? '', 10)

function createApolloClient(options?: Partial<ApolloClientOptions<NormalizedCacheObject>>) {
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      // in `JobStatus` `isrc` is the unqiue id for the status of the job
      // by convention apollo only looks for fields like `id`, `_id`, this provides an override
      typePolicies: { JobStatus: { keyFields: ['isrc'] } },
    }),
    defaultOptions: {
      // ? this how you would disable the cache for debugging
      // watchQuery: { fetchPolicy: 'no-cache' },
      // query: { fetchPolicy: 'no-cache' },
    },
    link: from([
      generateMissingCacheIds(),
      removeTypenameFromVariables(),
      new RetryLink({
        attempts: {
          // * do not retry requests excessively in tests
          max: Number.isNaN(retries) ? 5 : retries,
          retryIf: (error) => !!error,
        },
        delay: {
          initial: isTest() ? 1 : 300,
          jitter: true,
          max: Number.POSITIVE_INFINITY,
        },
      }),
      createSentryDebugLink(),
      graphQLErrorLink,
      createEntraIdTokenLink(),
      new HttpLink({
        fetch,
        headers: {
          accept: 'application/json',
        },
        uri: `${process.env.NEXT_PUBLIC_API_BASE_URI as string}/graphql`, //! uri MUST be absolute for SSR or testing
      }),
    ]),
    ssrMode: typeof window === 'undefined',

    ...options,
  })
}

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  options?: Partial<ApolloClientOptions<NormalizedCacheObject>>,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = createApolloClient(options)

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient
  }

  if (initialState) {
    const existingCache = _apolloClient.extract()

    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return apolloClient
}

type ApolloPageProps = {
  props: Record<string, unknown>
  revalidate?: number
}

export function addApolloState(client: typeof apolloClient, pageProps: ApolloPageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(initialState?: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> {
  return useMemo(() => initializeApollo(initialState), [initialState])
}
