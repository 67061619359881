import { setContext } from '@apollo/client/link/context'
import { AuthError } from '@azure/msal-browser'

import { authState } from 'hooks/use-authentication'
import { setException } from 'state/use-exception'
import { TOKEN_REQUEST_OPTION } from 'utils/sso/config'
import { client } from 'utils/sso/sso'

const ensureLongSession = {
  // * `forceRefresh` and `refreshTokenExpirationOffsetSeconds` are used to ensure that on each page navigation
  // * the user can silently acquire an access token for at least the configured timeframe
  // * if it is not the case, the user will be prompted to sign in again
  // * this is to minimize the risk of the user losing work under the assumption
  // * that most edits will be completed within a certain timeframe
  forceRefresh: true,
  refreshTokenExpirationOffsetSeconds: 3 * 60 * 60, // token should be valid for at least 3 hours
}

/**
 * Acquires an access token required to authenticate the user against the AdminAPI
 * from Microsoft Entra (Azure AD) and sets it on an `Authorization`-Header.
 *
 * At this point the user has to be logged in because the existence of a session is
 * already checked in AuthenticationGuard.
 */
export const createEntraIdTokenLink = () =>
  setContext(async (_operation, context) => {
    const headers: Record<string, string> = (context.headers as Record<string, string>) || {}
    const account = client.getAllAccounts()[0]
    const tokenRequest = {
      ...TOKEN_REQUEST_OPTION,
      account,
      /* NOTE use long session params only on queries that contain `byId` which is
       * our convention for loading form content, which is were a loss of data is most critical
       * this is not rock- solid but likely good enough, improve eg. with graphql client-only directives if needed
       */
      ...(/byid/i.test(_operation.operationName ?? '') ? ensureLongSession : {}),
    }
    return client
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        return { headers: { ...headers, authorization: `Bearer ${response.accessToken}` } }
      })
      .catch((error) => {
        if (error instanceof AuthError) {
          // set external error state for authentication guard to show login prompt
          // cleared on successful login in * LINK hooks/use-authentication.ts
          authState([null, error])
        } else {
          setException(new Error('Unknown error in entra-link', { cause: error }))
        }
      })
  })
