import type {
  Album,
  BannerData,
  CoreArtistData,
  CoreEpochData,
  CoreGenreData,
  CoreGroupData,
  CorePartnerData,
  CoreRole,
  CoreWorkData,
  CuratedSliderData,
  LiveConcertData,
  Maybe,
  R2Work,
  Scalars,
  TranslatedString,
  UserInfoFragment,
  VideoData,
  VodConcert,
  VodConcertData,
} from 'generated/types'

import {
  AlbumInfoFragmentDoc,
  LiveConcertDataFragmentDoc,
  VideoDataFragmentDoc,
  VodConcertDataFragmentDoc,
} from 'generated/operations/asset-data'
import {
  CoreArtistDataFragmentDoc,
  CoreGroupDataFragmentDoc,
  CorePartnerDataFragmentDoc,
  CoreWorkDataFragmentDoc,
} from 'generated/operations/core'
import { BannerDataFragmentDoc, CuratedSliderDataFragmentDoc } from 'generated/operations/curated-content'
import { UserInfoFragmentDoc } from 'generated/operations/user-management'
import { PublicationStatus } from 'generated/types'
import { isError, isString } from 'remeda'
import { match } from 'ts-pattern'

import { apolloClient } from 'utils/apollo/client'
import { logger } from 'utils/error-reporting/logger'
import { pickDraftOrPublished, publicationStatusToSearchIndexPublicationStatus } from 'utils/graphql/data-helpers'
import { isNotNullish, isNullish } from 'utils/ts/type-guards'

import type { Hit } from './algolia'

import { type SearchIndexBase, SearchIndexDocumentType, type SearchIndexDocuments } from './document-types'

const getConfig = (type: SearchIndexDocuments, id: Scalars['ID']['output']) =>
  match(type)
    .with(SearchIndexDocumentType.album, () => ({
      fragment: AlbumInfoFragmentDoc,
      fragmentName: 'AlbumInfo',
      id: `Album:${id}`,
    }))
    .with(SearchIndexDocumentType.artist, () => ({
      fragment: CoreArtistDataFragmentDoc,
      fragmentName: 'CoreArtistData',
      id: `CoreArtistData:${id}`,
    }))
    .with(SearchIndexDocumentType.banner, () => ({
      fragment: BannerDataFragmentDoc,
      fragmentName: 'BannerData',
      id: `BannerData:${id}`,
    }))
    .with(SearchIndexDocumentType.curatedSlider, () => ({
      fragment: CuratedSliderDataFragmentDoc,
      fragmentName: 'CuratedSliderData',
      id: `CuratedSliderData:${id}`,
    }))
    .with(SearchIndexDocumentType.group, () => ({
      fragment: CoreGroupDataFragmentDoc,
      fragmentName: 'CoreGroupData',
      id: `CoreGroupData:${id}`,
    }))
    .with(SearchIndexDocumentType.liveConcert, () => ({
      fragment: LiveConcertDataFragmentDoc,
      fragmentName: 'LiveConcertData',
      id: `LiveConcertData:${id}`,
    }))
    .with(SearchIndexDocumentType.partner, () => ({
      fragment: CorePartnerDataFragmentDoc,
      fragmentName: 'CorePartnerData',
      id: `CorePartnerData:${id}`,
    }))
    .with(SearchIndexDocumentType.work, () => ({
      fragment: CoreWorkDataFragmentDoc,
      fragmentName: 'CoreWorkData',
      id: `CoreWorkData:${id}`,
    }))
    .with(SearchIndexDocumentType.vodConcert, () => ({
      fragment: VodConcertDataFragmentDoc,
      fragmentName: 'VodConcertData',
      id: `VodConcertData:${id}`,
    }))
    .with(SearchIndexDocumentType.video, () => ({
      fragment: VideoDataFragmentDoc,
      fragmentName: 'VideoData',
      id: `VideoData:${id}`,
    }))
    .with(SearchIndexDocumentType.user, () => ({
      fragment: UserInfoFragmentDoc,
      fragmentName: 'UserInfo',
      id: `User:${id}`,
    }))
    .run()

type SearchDocumentFragmentMap = {
  [SearchIndexDocumentType.album]: Album
  [SearchIndexDocumentType.artist]: CoreArtistData
  [SearchIndexDocumentType.banner]: BannerData
  [SearchIndexDocumentType.curatedSlider]: CuratedSliderData
  [SearchIndexDocumentType.epoch]: CoreEpochData
  [SearchIndexDocumentType.genre]: CoreGenreData
  [SearchIndexDocumentType.group]: CoreGroupData
  [SearchIndexDocumentType.liveConcert]: LiveConcertData
  [SearchIndexDocumentType.partner]: CorePartnerData
  [SearchIndexDocumentType.r2Work]: R2Work
  [SearchIndexDocumentType.role]: CoreRole
  [SearchIndexDocumentType.user]: UserInfoFragment
  [SearchIndexDocumentType.video]: VideoData
  [SearchIndexDocumentType.vodConcert]: VodConcertData
  [SearchIndexDocumentType.work]: CoreWorkData
}

type SharedDocumentFields = {
  __typename: string
  endTime: Maybe<string>
  name: Maybe<TranslatedString>
  startTime: Maybe<string>
  title: Maybe<TranslatedString>
  vodConcert: Maybe<VodConcert>
}

// ! we do not want to re-implement the backend mapping-logic to Algolia-Documents
// ! so we only override the fields that are visible in the list views AND where the changes will be most visible to the editors
// ! extend this list as needed
const genericMapper = (cacheData: Partial<SharedDocumentFields> | undefined) => {
  if (cacheData === undefined) {
    return
  }

  const override = {
    end_time: cacheData?.endTime,
    name: cacheData.name?.en,

    // live-concert-list-view
    start_time: cacheData?.startTime,
    title: cacheData?.title?.en,
    vod_concert_id: cacheData?.vodConcert === null ? null : cacheData?.vodConcert?.id,
  }

  // * do not include overrides that are not relevant for this document type
  return Object.fromEntries(Object.entries(override).filter(([, value]) => value !== undefined))
}

type DocumentWithDraft =
  | typeof SearchIndexDocumentType.artist
  | typeof SearchIndexDocumentType.banner
  | typeof SearchIndexDocumentType.curatedSlider
  | typeof SearchIndexDocumentType.group
  | typeof SearchIndexDocumentType.liveConcert
  | typeof SearchIndexDocumentType.partner
  | typeof SearchIndexDocumentType.video
  | typeof SearchIndexDocumentType.vodConcert
  | typeof SearchIndexDocumentType.work
const isDocumentWithDraft = (type: SearchIndexDocuments): type is DocumentWithDraft =>
  match(type)
    .with(
      SearchIndexDocumentType.banner,
      SearchIndexDocumentType.curatedSlider,
      SearchIndexDocumentType.artist,
      SearchIndexDocumentType.group,
      SearchIndexDocumentType.liveConcert,
      SearchIndexDocumentType.partner,
      SearchIndexDocumentType.video,
      SearchIndexDocumentType.vodConcert,
      SearchIndexDocumentType.work,
      () => true,
    )
    .otherwise(() => false)

type DocumentWithStatus = typeof SearchIndexDocumentType.album | typeof SearchIndexDocumentType.user
const isDocumentWithStatus = (type: SearchIndexDocuments): type is DocumentWithStatus =>
  match(type)
    .with(SearchIndexDocumentType.album, SearchIndexDocumentType.user, () => true)
    .otherwise(() => false)

/**
 * Data in Algolia is eventually consistent. This creates a challenge how to provide good feedback to the editors when
 * they change data and especially the publication status of a document.
 *
 * This implements limited optimistic UI patterns to provide better UX. See ADR 17 for more information.
 */
export const getMoreRecentDataFromCache = <HitType extends SearchIndexBase>(hits: Array<Hit<HitType>>) =>
  hits
    .map((hit) => {
      const { objectID, type } = hit

      if (!(isDocumentWithDraft(type) || isDocumentWithStatus(type))) {
        return hit
      }

      try {
        const cacheConfig = getConfig(type, objectID)
        const cacheData = apolloClient.readFragment<SearchDocumentFragmentMap[typeof type]>(cacheConfig)

        if (isNullish(cacheData)) {
          return hit
        }

        // * element has been deleted => drop it
        if (cacheData.status === PublicationStatus.Deleted && isString(cacheData.id)) {
          logger.debug('Removing deleted document from search results (ADR-017)', { objectID })
          return null
        }

        const publicationStatus = publicationStatusToSearchIndexPublicationStatus(cacheData.status)

        // * `DocumentWithStatus` has only status changes, info is not editable
        if (isDocumentWithStatus(type)) {
          logger.debug('Override publication status of search result (ADR-017).', { objectID, publicationStatus })
          return {
            ...hit,
            publication_status: publicationStatus,
          }
        }

        type TCacheDataWithDraft = Exclude<typeof cacheData, Album | UserInfoFragment>
        const document = pickDraftOrPublished<TCacheDataWithDraft['draft'], TCacheDataWithDraft['published']>(
          cacheData as TCacheDataWithDraft,
        )
        const override = genericMapper(document)

        const result = {
          ...hit,
          ...override,
          publication_status: publicationStatus,
        }

        logger.debug('Override info of search result (ADR-017)', {
          after: result,
          before: hit,
        })
        return result
      } catch (error: unknown) {
        if (isError(error)) {
          logger.error(error)
        } else {
          logger.error('Unknown error', { extra: { error } })
        }
        return hit
      }
    })
    .filter(isNotNullish)
