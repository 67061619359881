import type { Nullish, UnknownObject } from 'utils/ts/utility-types'

import { isNonEmptyObject } from 'utils/is-empty-object'

import type { GraphQLType } from './shared-types'

export function isNullish(value: unknown): value is Nullish {
  // biome-ignore lint/suspicious/noDoubleEquals: makes nullish check work
  return value == undefined
}

export function isNotNullish<T>(value: T): value is NonNullable<T> {
  return value != null
}

export function isNull<T>(term: null | T): term is null {
  return term === null
}

export function isObject<T extends UnknownObject, U>(term: T | U): term is NonNullable<T> {
  return !isNull(term) && typeof term === 'object'
}

export const isSchemaType =
  <TTarget extends GraphQLType>(targetType: string) =>
  (value: unknown): value is TTarget =>
    Boolean(isNotNullish(value) && isNonEmptyObject(value) && '__typename' in value && value.__typename === targetType)
