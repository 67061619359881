import type { PublicationStatus, Scalars } from 'generated/types'
import type { BaseSyntheticEvent, PropsWithChildren, Reducer } from 'react'
import type { FieldValues } from 'react-hook-form'

import { createContext, useContext, useMemo, useReducer } from 'react'
import { useFormContext } from 'react-hook-form'
import { P, match } from 'ts-pattern'

import type { FormHelper } from 'components/form/form'
import type { UnknownObject } from 'utils/ts/utility-types'

import { isEmptyObject } from 'utils/is-empty-object'
import { isNotNullish } from 'utils/ts/type-guards'

type AdditionalFormContext = {
  // * flag to indicate if we currently upload a file, form submission should not be possible in this case
  isUploading: boolean

  // * the publication state of the entity we are editing, used eg. in determining if we should show a link
  // * the gql-id of the entity we are currently editing
  nodeId: Scalars['ID']['output'] | undefined

  // * to the published page
  publicationStatus: PublicationStatus | undefined
}

export type FormSubmitHandler<
  TFormOutputValues extends UnknownObject = UnknownObject,
  TFormValues extends UnknownObject = UnknownObject,
> = (
  onSubmit: (outputValues: TFormOutputValues, formHelper: FormHelper<TFormValues>) => Promise<unknown>,
) => (event?: BaseSyntheticEvent | undefined) => Promise<unknown>

type AdditionalFormContextMutations<
  TFormValues extends UnknownObject = UnknownObject,
  TFormOutputValues extends UnknownObject = UnknownObject,
> = {
  handleSubmit: FormSubmitHandler<TFormOutputValues, TFormValues>
  setIsUploading: (nextIsUploading: boolean) => void
}

const AdditionalFormContext = createContext<AdditionalFormContext | null>(null)
const AdditionalFormDispatchContext = createContext<AdditionalFormContextMutations | null>(null)

enum Actions {
  setIsUploading = 0,
}

type AugmentedFormContextState = AdditionalFormContext

type AugmentedFormContextAction = {
  type: Actions.setIsUploading
  value: boolean
}

function reducer(state: AugmentedFormContextState, action: AugmentedFormContextAction) {
  return match<[AugmentedFormContextState, AugmentedFormContextAction], AugmentedFormContextState>([state, action])
    .with([state, { type: Actions.setIsUploading, value: P.boolean }], ([, { value }]) => ({
      ...state,
      isUploading: value,
    }))
    .otherwise(() => state)
}

const initialState = {
  isUploading: false,
}

type AdditionalFormContextProviderProps<
  TFormValues extends UnknownObject = UnknownObject,
  TFormOutputValues extends UnknownObject = UnknownObject,
> = PropsWithChildren<
  Omit<AdditionalFormContext, 'isUploading'> &
    Pick<AdditionalFormContextMutations<TFormValues, TFormOutputValues>, 'handleSubmit'>
>
export const AdditionalFormContextProvider = <
  TFormValues extends UnknownObject = UnknownObject,
  TFormOutputValues extends UnknownObject = UnknownObject,
>({
  children,
  handleSubmit,
  nodeId,
  publicationStatus,
}: AdditionalFormContextProviderProps<TFormValues, TFormOutputValues>) => {
  /**
   * This is not handled as global state because form state by its very nature is local
   * in theory it should be possible to have more than one form on one page.
   *
   * We also split the properties that we read like `isUploading` from the mutations that
   * change the additional form state we are managing in this context. This is that the changing
   * of unconnected values do not cause a rerender in the connected components
   *
   * @link https://beta.reactjs.org/learn/scaling-up-with-reducer-and-context
   */
  const [state, dispatch] = useReducer<Reducer<AugmentedFormContextState, AugmentedFormContextAction>>(reducer, {
    ...initialState,
    nodeId,
    publicationStatus,
  })

  const mutations: AdditionalFormContextMutations<TFormValues, TFormOutputValues> = useMemo(
    () => ({
      handleSubmit,
      setIsUploading: (nextIsUploading: boolean): void =>
        dispatch({ type: Actions.setIsUploading, value: nextIsUploading }),
    }),
    [handleSubmit],
  )

  return (
    <AdditionalFormContext.Provider value={state}>
      <AdditionalFormDispatchContext.Provider
        value={mutations as AdditionalFormContextMutations<UnknownObject, UnknownObject>}
      >
        {children}
      </AdditionalFormDispatchContext.Provider>
    </AdditionalFormContext.Provider>
  )
}

/**
 * Merges the Form Context of React Hooks Form with some custom information and properties that we need
 * additionally in our forms.
 *
 * You can use everything that is returned by RHF `useFormContext` plus the additional values
 */
export function useAugmentedFormContext<
  TFormOutputValues extends FieldValues = UnknownObject,
  TFormValues extends FieldValues = UnknownObject,
>() {
  const additionalContextValues = useContext(AdditionalFormContext)
  const additionalContextMutations = useContext(AdditionalFormDispatchContext)
  const formContext = useFormContext()

  return {
    ...formContext,
    ...(additionalContextValues as AdditionalFormContext),
    ...(additionalContextMutations as AdditionalFormContextMutations<TFormValues, TFormOutputValues>),
    canSubmit: !formContext.formState.isSubmitting && !additionalContextValues?.isUploading,
    // ! we use our own custom isDirty because react-hook-form's isDirty was not reliable in all cases
    // ! there is a [github issue](https://github.com/react-hook-form/react-hook-form/issues/4740) but its
    // ! suggestions were unsatisfactory because we do provide all default values.
    // ! The weirdness that `isDirty` can be true while `dirtyFields` is empty does not make sense even with
    // ! the explanations provided. We could not dig much deeper at the time due to time constraints, but if this
    // ! gives you problems it might need more investigation
    isDirty: !isEmptyObject(formContext.formState.dirtyFields),
    isEditForm: isNotNullish(additionalContextValues?.nodeId),
  }
}
