import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser'
import { type ReactNode, useCallback, useEffect } from 'react'

import { Button } from 'components/elements/button'
import { Spinner } from 'components/elements/spinner'
import { useAdminUser } from 'hooks/use-admin-user'
import { useAuthentication } from 'hooks/use-authentication'
import { logUnknownError } from 'utils/error-reporting/log-unknown-error'
import { t } from 'utils/i18n/translate'
import { getTranslationKeyFormatter } from 'utils/i18n/translation-key-formatter'
import { TOKEN_REQUEST_OPTION } from 'utils/sso/config'

import { LoginPromptContainer } from './login-prompt-container'

type AuthenticationGuardProps = {
  children: ReactNode
}

const tk = getTranslationKeyFormatter('auth')

/**
 * Handles authenticating the user and acquiring a "session", also UI for all error-cases.
 *
 * ID-Token and Access-Token are requested once initially. Fresh Access and Refresh Tokens are then
 * requested in * LINK utils/apollo/links/entra-token-link.ts while loading content from the Admin API
 */
export function AuthenticationGuard({ children }: AuthenticationGuardProps) {
  const { adminUser, inProgress } = useAdminUser()
  const { acquireToken, error, login } = useAuthentication(InteractionType.Silent, TOKEN_REQUEST_OPTION)

  const handleLogin = useCallback(() => {
    void login(InteractionType.Popup, TOKEN_REQUEST_OPTION).catch((error) => {
      logUnknownError(error)
    })
  }, [login])

  // attempt to acquire token when browser tab becomes visible again
  // assumes that the user wants to resume work after a while
  // this is a simple measure more complex logic could be achieved with detecting idle-time
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        acquireToken(InteractionType.Silent).catch(() => {
          // error handled in useAuthentication-Hook
        })
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [acquireToken])

  if (inProgress === InteractionStatus.Login || inProgress === InteractionStatus.AcquireToken) {
    return (
      <Spinner isLoading={true}>
        <LoginPromptContainer headline="Logging in">
          <p>{t(tk('popup_opened'), 'Please complete the operation in the opened popup.')}</p>
        </LoginPromptContainer>
      </Spinner>
    )
  }

  if (inProgress === InteractionStatus.Logout) {
    return (
      <Spinner isLoading={true}>
        <LoginPromptContainer headline="Logging out">
          <p>{t(tk('logout_started'), 'Initiated logout. Please wait.')}</p>
        </LoginPromptContainer>
      </Spinner>
    )
  }

  if (inProgress !== InteractionStatus.None) {
    return (
      <Spinner isLoading={true}>
        <LoginPromptContainer headline="Application loading">
          <p>
            {t(
              tk('in_progress', 'message'),
              'We are trying to authenticate you with the UMG SSO. Please wait a moment.',
            )}
          </p>
        </LoginPromptContainer>
      </Spinner>
    )
  }

  if (error instanceof InteractionRequiredAuthError) {
    return (
      <LoginPromptContainer headline="Authenticate with UMG SSO">
        <p>
          {t(
            tk('login_prompt', 'message'),
            'This application uses your UMG Account for sign-in. You need to be assigned to this App by an Administrator in order to use it and have the necessary permissions.',
          )}
        </p>
        <div className="mt-5">
          <Button onClick={handleLogin}>{t(tk('login_button', 'label'), 'Login with UMG Account')}</Button>
        </div>
      </LoginPromptContainer>
    )
  }

  if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
    return (
      <LoginPromptContainer headline="You cancelled the login">
        <p>
          {t(
            tk('login_cancelled', 'message'),
            'You cancelled the login process. If you want to use this application, you need to sign in with your UMG Account. Press the login-button below to try again.',
          )}
        </p>
        <div className="mt-5">
          <Button onClick={handleLogin}>{t(tk('retry_login_button', 'label'), 'Retry Login')}</Button>
        </div>
      </LoginPromptContainer>
    )
  }

  if (error instanceof BrowserAuthError) {
    return (
      <LoginPromptContainer headline="Something went wrong">
        <p>
          {t(
            tk('login_error', 'message'),
            'Something went wrong during your last login attempt. Please try again by clicking on the button below. If this issue persists please try to reload the page.',
          )}
        </p>
        <div className="mt-5">
          <Button onClick={handleLogin}>{t(tk('retry_login_button', 'label'), 'Retry Login')}</Button>
        </div>
      </LoginPromptContainer>
    )
  }

  // ! if at this point we still have no admin user then something went wrong
  if (!adminUser) {
    return (
      <Spinner isLoading={true}>
        <LoginPromptContainer headline="Unexpected Error">
          {t(
            tk('fatal_error_message', 'message'),
            'Something went wrong during your last login attempt. Please try to reload the page',
          )}
        </LoginPromptContainer>
      </Spinner>
    )
  }

  return <>{children}</>
}
