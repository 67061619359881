import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const PublicationErrorFieldsFragmentDoc = gql`
    fragment PublicationErrorFields on PublicationError {
  reason
}
    `
export const UniqueIsrcPublicationErrorFieldsFragmentDoc = gql`
    fragment UniqueIsrcPublicationErrorFields on UniqueIsrcPublicationError {
  reason
  isrc
  conflictingNodes {
    id
    type
  }
}
    `
export const ValidationErrorFieldsFragmentDoc = gql`
    fragment ValidationErrorFields on ValidationError {
  field
  validation
}
    `
export const AdminApiErrorFragmentDoc = gql`
    fragment AdminApiError on ApiError {
  __typename
  message
  path
  ... on PublicationError {
    ...PublicationErrorFields
  }
  ... on UniqueIsrcPublicationError {
    ...UniqueIsrcPublicationErrorFields
  }
  ... on ValidationError {
    ...ValidationErrorFields
  }
  ... on AuthenticationError {
    code
  }
}
    ${PublicationErrorFieldsFragmentDoc}
${UniqueIsrcPublicationErrorFieldsFragmentDoc}
${ValidationErrorFieldsFragmentDoc}`
export const PictureInfoFragmentDoc = gql`
    fragment PictureInfo on Picture {
  id
  type
  url
}
    `
export const ReferencedPicturesFragmentDoc = gql`
    fragment ReferencedPictures on PictureConnection {
  edges {
    status
    node {
      ...PictureInfo
    }
  }
}
    ${PictureInfoFragmentDoc}`
export const ReferencedPartnersFragmentDoc = gql`
    fragment ReferencedPartners on CorePartnerConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
      type
    }
  }
}
    `
export const ReferencedGroupsFragmentDoc = gql`
    fragment ReferencedGroups on CoreGroupConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const ReferencedArtistsFragmentDoc = gql`
    fragment ReferencedArtists on CoreArtistConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const ReferencedArtistsWithRoleFragmentDoc = gql`
    fragment ReferencedArtistsWithRole on CoreArtistWithRoleConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
    role {
      id
      type
      subtype
    }
  }
}
    `
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on CoreRole {
  id
  type
  subtype
  displayName
}
    `
export const ReferencedRolesFragmentDoc = gql`
    fragment ReferencedRoles on CoreRoleConnection {
  edges {
    status
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const ReferencedListRolesFragmentDoc = gql`
    fragment ReferencedListRoles on CoreRoleListConnection {
  edges {
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const AllLocalesFragmentDoc = gql`
    fragment AllLocales on TranslatedString {
  de
  en
  ja
}
    `
export const ReferencedAlbumsFragmentDoc = gql`
    fragment ReferencedAlbums on AlbumConnection {
  edges {
    status
    node {
      id
      title {
        ...AllLocales
      }
    }
  }
}
    ${AllLocalesFragmentDoc}`
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `
export const DraftEditorFragmentDoc = gql`
    fragment DraftEditor on AdminUser {
  id
  name
  email
}
    `
export const StreamInfoFragmentDoc = gql`
    fragment StreamInfo on Stream {
  id
  url
  mvVideoUrl
}
    `
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  externalId
  email
  amazonCustomerId
  appleAppAccountToken
  title
  firstName
  lastName
  status
  accountConfirmed
  dateRegistered
  locale
  country
  affiliateGroup
  customerGroup
  registrationCountry
  lastAuthenticatedAt
  sendNewsletter
  hasTrialedBefore
  stripeCurrency
  stripeCustomerId
  status
  mixpanelTrackingAllowed
  metaTrackingAllowed
  ticket {
    amazonOriginalReceiptId
    amazonProductId
    appleOriginalTransactionId
    appleProductId
    canceledAt
    deletedAt
    insertedAt
    interval
    paymentMethodType
    paymentProvider
    renewedAt
    status
    stripePriceId
    stripeProductId
    stripeSubscriptionId
    ticketType
    trialEndsAt
    validUntil
    validUntilCalculated
    voucherCode
    voucherDuration
    voucherDurationInMonths
    voucherId
    voucherPercentageOff
  }
  inactiveTickets {
    amazonOriginalReceiptId
    amazonProductId
    appleOriginalTransactionId
    appleProductId
    canceledAt
    deletedAt
    insertedAt
    interval
    interval
    paymentMethodType
    paymentProvider
    renewedAt
    status
    stripePriceId
    stripeProductId
    stripeSubscriptionId
    ticketType
    trialEndsAt
    validUntil
    validUntilCalculated
    voucherCode
    voucherDuration
    voucherDurationInMonths
    voucherId
    voucherPercentageOff
  }
  devices {
    deviceInfo
    externalId
  }
  postalAddress {
    firstLine
    secondLine
    city
    zipCode
    province
    district
    country
  }
}
    `
export const DeleteAudienceUserDocument = gql`
    mutation DeleteAudienceUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...UserInfo
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${UserInfoFragmentDoc}`
export type DeleteAudienceUserMutationFn = Apollo.MutationFunction<
  Types.DeleteAudienceUserMutation,
  Types.DeleteAudienceUserMutationVariables
>

/**
 * __useDeleteAudienceUserMutation__
 *
 * To run a mutation, you first call `useDeleteAudienceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAudienceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAudienceUserMutation, { data, loading, error }] = useDeleteAudienceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAudienceUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteAudienceUserMutation, Types.DeleteAudienceUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteAudienceUserMutation, Types.DeleteAudienceUserMutationVariables>(
    DeleteAudienceUserDocument,
    options,
  )
}
export type DeleteAudienceUserMutationHookResult = ReturnType<typeof useDeleteAudienceUserMutation>
export type DeleteAudienceUserMutationResult = Apollo.MutationResult<Types.DeleteAudienceUserMutation>
export type DeleteAudienceUserMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteAudienceUserMutation,
  Types.DeleteAudienceUserMutationVariables
>
export const BlockAudienceUserDocument = gql`
    mutation BlockAudienceUser($input: BlockUserInput!) {
  blockUser(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...UserInfo
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${UserInfoFragmentDoc}`
export type BlockAudienceUserMutationFn = Apollo.MutationFunction<
  Types.BlockAudienceUserMutation,
  Types.BlockAudienceUserMutationVariables
>

/**
 * __useBlockAudienceUserMutation__
 *
 * To run a mutation, you first call `useBlockAudienceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockAudienceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockAudienceUserMutation, { data, loading, error }] = useBlockAudienceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockAudienceUserMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.BlockAudienceUserMutation, Types.BlockAudienceUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.BlockAudienceUserMutation, Types.BlockAudienceUserMutationVariables>(
    BlockAudienceUserDocument,
    options,
  )
}
export type BlockAudienceUserMutationHookResult = ReturnType<typeof useBlockAudienceUserMutation>
export type BlockAudienceUserMutationResult = Apollo.MutationResult<Types.BlockAudienceUserMutation>
export type BlockAudienceUserMutationOptions = Apollo.BaseMutationOptions<
  Types.BlockAudienceUserMutation,
  Types.BlockAudienceUserMutationVariables
>
export const UnblockAudienceUserDocument = gql`
    mutation UnblockAudienceUser($input: UnblockUserInput!) {
  unblockUser(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...UserInfo
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${UserInfoFragmentDoc}`
export type UnblockAudienceUserMutationFn = Apollo.MutationFunction<
  Types.UnblockAudienceUserMutation,
  Types.UnblockAudienceUserMutationVariables
>

/**
 * __useUnblockAudienceUserMutation__
 *
 * To run a mutation, you first call `useUnblockAudienceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockAudienceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockAudienceUserMutation, { data, loading, error }] = useUnblockAudienceUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockAudienceUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnblockAudienceUserMutation,
    Types.UnblockAudienceUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnblockAudienceUserMutation, Types.UnblockAudienceUserMutationVariables>(
    UnblockAudienceUserDocument,
    options,
  )
}
export type UnblockAudienceUserMutationHookResult = ReturnType<typeof useUnblockAudienceUserMutation>
export type UnblockAudienceUserMutationResult = Apollo.MutationResult<Types.UnblockAudienceUserMutation>
export type UnblockAudienceUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UnblockAudienceUserMutation,
  Types.UnblockAudienceUserMutationVariables
>
export const DeleteUserAddressDocument = gql`
    mutation DeleteUserAddress($input: DeleteUserAddressInput!) {
  deleteUserAddress(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...UserInfo
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${UserInfoFragmentDoc}`
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<
  Types.DeleteUserAddressMutation,
  Types.DeleteUserAddressMutationVariables
>

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteUserAddressMutation, Types.DeleteUserAddressMutationVariables>(
    DeleteUserAddressDocument,
    options,
  )
}
export type DeleteUserAddressMutationHookResult = ReturnType<typeof useDeleteUserAddressMutation>
export type DeleteUserAddressMutationResult = Apollo.MutationResult<Types.DeleteUserAddressMutation>
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteUserAddressMutation,
  Types.DeleteUserAddressMutationVariables
>
export const GetUserByIdDocument = gql`
    query getUserById($id: ID!) {
  user(id: $id) {
    id
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables> &
    ({ variables: Types.GetUserByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>(GetUserByIdDocument, options)
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>(GetUserByIdDocument, options)
}
export function useGetUserByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>(GetUserByIdDocument, options)
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>
export type GetUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserByIdSuspenseQuery>
export type GetUserByIdQueryResult = Apollo.QueryResult<Types.GetUserByIdQuery, Types.GetUserByIdQueryVariables>
