import type { ComponentPropsWithoutRef } from 'react'

import { clsx } from 'clsx'

type SpinnerProps = {
  isLoading: boolean
  loadText?: string
  showChildrenWhileLoading?: boolean
} & ComponentPropsWithoutRef<'div'>

export function Spinner({
  children,
  isLoading = true,
  loadText = 'Loading',
  showChildrenWhileLoading = true,
  ...props
}: SpinnerProps) {
  return (
    <div className={clsx({ relative: isLoading })} {...props}>
      {isLoading && (
        <div
          className="absolute z-10 flex h-full w-full cursor-wait items-center justify-center bg-gray-100 bg-opacity-50 font-bold"
          data-testid="loading-indicator"
        >
          <span className="animate-pulse text-lg text-gray-500">{loadText}...</span>
        </div>
      )}
      {showChildrenWhileLoading || !isLoading ? children : null}
    </div>
  )
}
