export const ENTRA_SSO_CONFIG = {
  clientId: process.env.NEXT_PUBLIC_ENTRA_SSO_CLIENT_ID as string,
  impersonateScope: process.env.NEXT_PUBLIC_ENTRA_SSO_IMPERSONATE_SCOPE as string,
  redirectUri: process.env.NEXT_PUBLIC_ENTRA_SSO_REDIRECT_URI as string,
  tenantId: process.env.NEXT_PUBLIC_ENTRA_SSO_TENANT_ID as string,
} as const

export const TOKEN_REQUEST_OPTION = {
  scopes: [ENTRA_SSO_CONFIG.impersonateScope],
}
