import { isError, isString } from 'remeda'

import { logger } from './logger'

export function logUnknownError(error: unknown) {
  if (isError(error)) {
    logger.error(error)
  } else if (isString(error)) {
    logger.error(new Error(error))
  } else {
    logger.error(new Error('Neither string nor error returned from a catch clause'), { extra: { error } })
  }
}
