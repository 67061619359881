import { useRouter } from 'next/router'
import { useEffect } from 'react'

import type { LogMeta } from 'utils/error-reporting/sentry'

import { isBrowser } from 'utils/environment-guard'
import { logger } from 'utils/error-reporting/logger'
import { makeStateVariable, useStateVariable } from 'utils/state/state'

const exceptionVariable = makeStateVariable<Error | undefined>(undefined)

export const setException = (exception: Error | undefined, meta?: LogMeta) => {
  const previous = exceptionVariable()
  exceptionVariable(exception)

  if (previous !== exception && exception !== undefined) {
    logger.error(exception, meta)
  }
}

const resetException = () => {
  if (exceptionVariable() !== undefined) {
    logger.debug('Clearing exception state')
    exceptionVariable(undefined)
  }
}

/**
 * Can set a global exception which will display an error screen to the user
 *
 * Use for unexpected errors where we don not know how the user can mitigate them.
 *
 * Every exception will be automatically reported to sentry
 *
 * @example
 * const {setException} = useException()
 * setException(new Error('something bad happened'))
 */
export function useException() {
  const exception = useStateVariable(exceptionVariable)
  const router = useRouter()

  /**
   * if the app is an exception state do a hard reload when the user hits the back button
   * to clear the error state
   */
  useEffect(() => {
    if (isBrowser() && exception !== undefined) {
      router.beforePopState(({ url }) => {
        window.location.assign(url)
        return false
      })
    }
  }, [router, exception])

  /**
   * clear the exception state if the user navigates to a new page
   */
  useEffect(() => {
    if (isBrowser() && exception !== undefined) {
      router.events.on('routeChangeComplete', resetException)

      return () => router.events.off('routeChangeComplete', resetException)
    }
  }, [router, exception])

  return {
    hasException: Boolean(exception),
    resetException,
    setException,
  }
}
