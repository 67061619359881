import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { Modal, ModalHeadline, ModalParagraph, ModalStates } from 'components/elements/modal/modal'
import { useAugmentedFormContext } from 'hooks/use-augmented-form-context'
import { useModal } from 'state/use-modal'
import { t } from 'utils/i18n/translate'

// * There is no other way to prevent a route change in nextjs than throwing an error
// * we use this to filter the event out before it is sent to sentry
export const ABORT_ROUTE_CHANGE_ERROR_MESSAGE = 'Abort route change. Please ignore this error.'

/**
 * This checks if the user has unsaved changes and warns her when changing the URL.
 *
 * Due to some limitations in the browser we need to show different elements for an unload-event (eg. closing the tab
 * or reloading) because otherwise we cannot interrupt the native events.
 */
export function useWarnIfUnsavedChanges(shouldWarnOnDirty: boolean) {
  const router = useRouter()
  const { showModal } = useModal()
  const {
    formState: { isSubmitting },
    isDirty,
  } = useAugmentedFormContext()

  const hasConfirmedRef = useRef(false)
  const message = t('Do you want to leave?')

  useEffect(() => {
    // `isDirty` means that there are changes in the form, we disable this check while the form is submitting
    // because otherwise the dirty check would trigger when we redirect the user to the edit-page after a newly created
    // entity. The case that the submission fails is handled in the code that does the redirection so the user's data
    // should be reasonably save
    const routeChangeStart = (url: string) => {
      if (!(router.asPath !== url && isDirty === true && isSubmitting === false && hasConfirmedRef.current === false)) {
        return
      }

      router.events.emit('routeChangeError')
      void router.replace(router.asPath)
      void showModal(
        <Modal confirmLabel="Leave">
          <ModalHeadline>{message}</ModalHeadline>
          <ModalParagraph>
            {t('You have unsaved changes that will be lost if you proceed to leave the page.')}
          </ModalParagraph>
        </Modal>,
      ).then(([choice]) => {
        if (choice === ModalStates.confirmed) {
          hasConfirmedRef.current = true
          return router.push(url)
        }
      })

      throw ABORT_ROUTE_CHANGE_ERROR_MESSAGE
    }

    // This handles events that completely exit the SPA, like closing the tab or reloading
    // The check is simpler in this case, because we also want to catch the case when the user reloads the tab
    const beforeunload = (event: BeforeUnloadEvent) => {
      if (isDirty === true) {
        event.preventDefault()
        event.returnValue = message
        return message
      }
    }

    if (shouldWarnOnDirty) {
      window.addEventListener('beforeunload', beforeunload)
      router.events.on('routeChangeStart', routeChangeStart)
    }

    return () => {
      if (shouldWarnOnDirty) {
        window.removeEventListener('beforeunload', beforeunload)
        router.events.off('routeChangeStart', routeChangeStart)
      }
    }
  })
}
