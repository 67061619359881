import type { Configuration, EventPayload } from '@azure/msal-browser'

import { AuthError, BrowserCacheLocation, EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser'

import { authState } from 'hooks/use-authentication'
import { isNotNullish } from 'utils/ts/type-guards'

import { ENTRA_SSO_CONFIG } from './config'

// switch to enable entra debug logs in browser console
const ENABLE_SSO_DEBUG_LOGGING = false

const configuration: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${ENTRA_SSO_CONFIG.tenantId}`,
    clientId: ENTRA_SSO_CONFIG.clientId,
    postLogoutRedirectUri: '/',
    redirectUri: ENTRA_SSO_CONFIG.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string): void => {
        if (!ENABLE_SSO_DEBUG_LOGGING) {
          return
        }
        switch (level) {
          case LogLevel.Error: {
            console.error(message)
            return
          }
          case LogLevel.Info: {
            console.info(message)
            return
          }
          case LogLevel.Verbose: {
            console.debug(message)
            return
          }
          case LogLevel.Warning: {
            console.warn(message)
            return
          }
        }
      },
      piiLoggingEnabled: true,
    },
  },
}

const instance = new PublicClientApplication(configuration)

const getAccountFromPayload = (payload: EventPayload) =>
  isNotNullish(payload) && 'account' in payload ? payload.account : null

export const intializeSsoClient = () => {
  // This will update account state if a user signs in from another tab or window
  // react to events
  instance.enableAccountStorageEvents()

  instance.addEventCallback((event) => {
    const account = getAccountFromPayload(event.payload)
    if (event.eventType === EventType.LOGIN_SUCCESS && account) {
      instance.setActiveAccount(account)
    }

    if (event.eventType === EventType.ACCOUNT_REMOVED) {
      const activeAccount = instance.getActiveAccount()
      if (account && activeAccount?.homeAccountId === account?.homeAccountId) {
        authState([null, new AuthError('active_account_removed', 'Active Account was removed in ohter tab')])
      }
    }
  })
}

export const client = instance
