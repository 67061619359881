import type { InteractionStatus } from '@azure/msal-browser'

import * as z from 'zod'

// type separate from the AdminAPI-Admin User because the Admin API-Admin User
// cannot have roles as they are managed elsewhere. Remove inconsistency if
// opportunity arises
export type EntraAdminUser = {
  email: string
  id: string
  name: string | undefined
  roles: Role[]
}

export const entraRoleSchema = z.enum(['stream_engineer', 'customer_care', 'editor'])
export type Role = z.infer<typeof entraRoleSchema>
export const roleEnum = entraRoleSchema.Enum

export type AdminUserHookState = {
  adminUser: EntraAdminUser | null
  inProgress: InteractionStatus
  isAuthenticated: boolean
}
