import type { NormalizedCacheObject } from '@apollo/client'
import type { AppProps } from 'next/app'

import { ApolloProvider } from '@apollo/client'
import { MsalProvider } from '@azure/msal-react'
import dynamic from 'next/dynamic'

import type { RoutePermission } from 'utils/ts/shared-types'

import { ModalShim } from 'components/elements/modal/modal-shim'
import { ErrorBoundary } from 'features/error-boundary/error-boundary'
import { AuthenticationGuard } from 'features/navigation/authentication-guard'
import { AuthorizationGuard } from 'features/navigation/authorization-guard'
import { APOLLO_STATE_PROP_NAME, useApollo } from 'utils/apollo/client'
import { initializeSearchClient } from 'utils/search/algolia'
import { client, intializeSsoClient } from 'utils/sso/sso'

import 'flag-icons/css/flag-icons.min.css'
import 'styles/globals.css'

initializeSearchClient()
intializeSsoClient()

export type AppPage = {
  pageProps: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject
    minimumRequiredRole?: RoutePermission
  }
} & Omit<AppProps, 'pageProps'>

// * execute the app only on the client to avoid problems with SSR by leveraging dynamic imports
// * @see https://nextjs.org/docs/advanced-features/dynamic-import
const AppShell = dynamic(() => import('../features/navigation/app-shell').then((module_) => module_.AppShell), {
  ssr: false,
})

function App({ Component, pageProps }: AppPage) {
  const { [APOLLO_STATE_PROP_NAME]: apolloCache, minimumRequiredRole, ...props } = pageProps
  const apolloClient = useApollo(apolloCache)

  return (
    <MsalProvider instance={client}>
      <ApolloProvider client={apolloClient}>
        <AppShell>
          <ErrorBoundary>
            <AuthenticationGuard>
              <AuthorizationGuard minimumRequiredRole={minimumRequiredRole}>
                <Component {...props} />
              </AuthorizationGuard>
            </AuthenticationGuard>
          </ErrorBoundary>
        </AppShell>
        <ModalShim />
      </ApolloProvider>
    </MsalProvider>
  )
}

export default App
