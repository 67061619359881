/**
 * These are the types that describe the documents in Algolia. Unfortunately as opposed to GraphQL
 * we cannot generate these but need to keep them in-sync by hand.
 *
 * ? In theory we _could_ expose them through the Admin API as that is where the mapping to the index-types
 * ? happens but it would be an endpoint without any other application use which is probably rather confusing
 */

import type { PictureType, Scalars } from 'generated/types'

export enum SearchIndexPublicationStatus {
  // ! virtual state, not actually in search index
  deleted = 'deleted',
  draft = 'draft',
  published = 'published',

  publishedWithDraft = 'published_with_draft',
}

export enum TagFilter {
  hasImages = 'has_images',
  hasMissingSortLetterJa = 'has_missing_sort_letter_ja',
  hasMissingTranslationsJa = 'has_no_translation_ja',
  hasMultipleValidTickets = 'has_multiple_valid_tickets',
  hasNoImages = 'has_no_images',
  hasWrongCharsJa = 'has_wrong_chars_ja',
}

export const SearchIndexVisibleForType = {
  account_required: 'account_required',
  no_authentication: 'no_authentication',
  ticket_required: 'ticket_required',
} as const

export type SearchIndexVisibleFor = (typeof SearchIndexVisibleForType)[keyof typeof SearchIndexVisibleForType]

export const SearchIndexDocumentType = {
  album: 'album',
  artist: 'artist',
  banner: 'banner',
  curatedSlider: 'curated_slider',
  epoch: 'epoch',
  genre: 'genre',
  group: 'group',
  liveConcert: 'live_concert',
  partner: 'partner',
  r2Work: 'r2_work',
  role: 'role',
  user: 'user',
  video: 'video',
  vodConcert: 'vod_concert',
  work: 'work',
} as const

export type SearchIndexDocuments = (typeof SearchIndexDocumentType)[keyof typeof SearchIndexDocumentType]

export type SearchIndexBase = {
  _tags?: TagFilter[]
  images?: SearchIndexImage[]
  objectID: Scalars['ID']['output']
  publication_status: SearchIndexPublicationStatus
  type: SearchIndexDocuments
}

export type SearchIndexImage = {
  type: PictureType
  url: string
}

export type SearchIndexArtist = {
  default_role_id: null | string
  name: string
  party_id: null | number
  roles: string[]
  type: typeof SearchIndexDocumentType.artist
} & SearchIndexBase

export type SearchIndexWork = {
  composers: string[]
  movements: string[]
  title: string
  type: typeof SearchIndexDocumentType.work
} & SearchIndexBase

export type SearchIndexPartner = {
  name: string
  partner_subtype: null | string
  partner_type: string
  type: typeof SearchIndexDocumentType.partner
} & SearchIndexBase

export type SearchIndexGroup = {
  group_type: string
  name: string
  party_id: null | number
  type: typeof SearchIndexDocumentType.group
} & SearchIndexBase

export type SearchIndexLiveConcert = {
  artists: string[]
  end_time: null | string
  reruns: Array<{
    end_time: string
    start_time: string
    stream_start_time: string
    title: string
  }>
  start_time: null | string
  subtitle: string
  title: string
  type: typeof SearchIndexDocumentType.liveConcert
  vod_concert_id: null | string
  works: string[]
} & SearchIndexBase

export type SearchIndexVodConcert = {
  archive_release_date: null | string
  artists: string[]
  live_concert_id: null | string
  subtitle: string
  takedown_date: null | string
  title: string
  type: typeof SearchIndexDocumentType.vodConcert
  vod_concert_type: string
  works: string[]
} & SearchIndexBase

export type SearchIndexEpoch = {
  end_year: null | string
  start_year: string
  title: string
  type: typeof SearchIndexDocumentType.epoch
} & SearchIndexBase

export type SearchIndexGenre = {
  title: string
  type: typeof SearchIndexDocumentType.genre
} & SearchIndexBase

export type SearchIndexRole = {
  name: string
  role_subtype: string
  role_type: string
  type: typeof SearchIndexDocumentType.role
} & SearchIndexBase

export type SearchIndexVideo = {
  artists: string[]
  title: string
  type: typeof SearchIndexDocumentType.video
  video_type: string
  work: string
} & SearchIndexBase

export type SearchIndexAlbum = {
  artists: string
  atmos_upc: null | string
  r2_id: null | number
  title: string
  type: typeof SearchIndexDocumentType.album
  upc: string
} & SearchIndexBase

export type SearchIndexR2Work = {
  composers: null | string
  r2_id: number
  title: string
  type: typeof SearchIndexDocumentType.r2Work
} & SearchIndexBase

export type SearchIndexUser = {
  date_registered: null | string
  email: string
  external_id: string
  first_name: string
  last_authenticated_at: null | string
  last_name: string
  type: typeof SearchIndexDocumentType.user
  user_status: string
} & SearchIndexBase

export type SearchIndexCuratedSlider = {
  curated_content: string[]
  description: string
  headline: string
  slider_type: string
  type: typeof SearchIndexDocumentType.curatedSlider
  visible_for: SearchIndexVisibleFor[]
} & SearchIndexBase

export type SearchIndexBanner = {
  description: string
  link: string
  title: string
  type: typeof SearchIndexDocumentType.banner
} & SearchIndexBase

export type SearchIndexDocumentTypes =
  | SearchIndexAlbum
  | SearchIndexArtist
  | SearchIndexBanner
  | SearchIndexCuratedSlider
  | SearchIndexEpoch
  | SearchIndexGroup
  | SearchIndexLiveConcert
  | SearchIndexPartner
  | SearchIndexR2Work
  | SearchIndexRole
  | SearchIndexUser
  | SearchIndexVideo
  | SearchIndexVodConcert
  | SearchIndexWork
