import type { Operation } from '@apollo/client'

import { ApolloLink } from '@apollo/client'
import { addBreadcrumb } from '@sentry/browser'
import { Kind, type OperationDefinitionNode } from 'graphql'

export function extractDefinition(operation: Operation): OperationDefinitionNode {
  return operation.query.definitions.find((q) => q.kind === Kind.OPERATION_DEFINITION) as OperationDefinitionNode
}

export const createSentryDebugLink = () =>
  new ApolloLink((operation, forward) => {
    const operationDefinition = extractDefinition(operation)
    addBreadcrumb({
      category: `graphql.${operationDefinition.operation}`,
      data: {
        operationName: operation.operationName,
        variables: operation.variables ?? null,
      },
      level: 'info',
      message: `GraphQL ${operationDefinition.operation} sent`,
      type: 'query',
    })
    return forward(operation)
  })
