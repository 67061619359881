import type { AccountInfo } from '@azure/msal-browser'

import { useAccount, useMsal } from '@azure/msal-react'
import * as z from 'zod'

import { isNotNullish } from 'utils/ts/type-guards'

import { type AdminUserHookState, type EntraAdminUser, entraRoleSchema } from './admin-user-types'

const entraAccountSchema = z
  .object({
    idTokenClaims: z.object({
      oid: z.string(),
      roles: z.array(entraRoleSchema),
    }),
    name: z.string().optional(),
    username: z.string(),
  })
  .nullable()

const mapEntraToAdminUser = (entraAccount: AccountInfo | null): EntraAdminUser | null => {
  const validatedAccount = entraAccountSchema.parse(entraAccount)
  return validatedAccount === null
    ? null
    : {
        email: validatedAccount.username,
        id: validatedAccount.idTokenClaims.oid,
        name: validatedAccount.name,
        roles: validatedAccount.idTokenClaims?.roles || [],
      }
}

/**
 * Hook to wrap the functionality of getting the relevant subset of data of the currently logged in
 * entra id user.Also validates that the entra-data is in the shape we expect.
 */
export function useAdminUser(): AdminUserHookState {
  const { accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  return {
    adminUser: mapEntraToAdminUser(account),
    inProgress,
    isAuthenticated: isNotNullish(account),
  }
}
